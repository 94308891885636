document.addEventListener('DOMContentLoaded', () => {
    const sidebar = document.querySelector('.sidebar');
    const btnOpen = document.querySelector('.btmenu');
    const btnOpen2 = document.querySelector('.btjsmen');
    const headmensmart = document.querySelector('.headmensmart');
    const btnClose = document.querySelector('.sidebar__closeMenu');
    
    btnOpen.addEventListener('click', () => {
      sidebar.classList.add('show');
    });
  
    btnOpen2.addEventListener('click', () => {
      sidebar.classList.add('show');
      console.log('clique');
    });
    headmensmart.addEventListener('click', () => {
      sidebar.classList.add('show');
      console.log('clique');
    });    

    btnClose.addEventListener('click', () => {
      sidebar.classList.remove('show');
    });


    window.addEventListener('scroll', function() {
      var elements = document.getElementsByClassName('stickmen'); // Remplacez 'votre-classe' par le nom de votre classe
      for (var i = 0; i < elements.length; i++) {
          if (window.scrollY > 100) {
              elements[i].classList.add('sticky'); // Remplacez 'ma-classe' par le nom de la classe que vous souhaitez ajouter
          } else {
              elements[i].classList.remove('sticky');
          }
      }
  });

  });

// Smooth scroll for the navigation menu and links with .scrollto classes
var scrolltoOffset = 0;
jQuery(document).on('click', '.btheaddecouv', function(e) {

  if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
    var target = jQuery(this.hash);

    if (target.length) {
      e.preventDefault();

      var scrollto = target.offset().top - scrolltoOffset;

      if (jQuery(this).attr("href") == '#header') {
        scrollto = 0;
      }

      jQuery('html, body').animate({
        scrollTop: scrollto
      }, 1500, 'easeInOutExpo');

      return false;
    }
  }
});


  